import React, { useEffect, useState, useContext } from 'react';
import JuzgadosTable from './CRUD/JuzgadosTable';
import KeycloakConf from '../../api/keycloakConf';
import { Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ToastAlertDelete from '../../components/Alert/ToastAlertDelete/ToastAlertDelete';
import ConfirmationDialog from '../../components/Alert/ConfirmationDialog';
import ButtonAdd from '../../components/Button/ButtonAdd';
import RefreshButton from '../../components/Button/RefreshButton';
import { deleteJuzgado, fetchJuzgados } from '../../shared/services/JuzgadosService';
import Progress from '../../components/Progress/Progress';
import SearchInput from '../../components/Search/SearchInput';
const Juzgados = () => {

  const [juzgados, setJuzgados] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [pageable, setPageable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const { getToken } = useContext(KeycloakConf);
  const navigate = useNavigate();

  const loadJuzgados = async () => {
    setLoading(true);
    const token = getToken();
    try {
      const juzgadosData = await fetchJuzgados(token, page, rowsPerPage, '', searchQuery);
      setJuzgados(juzgadosData.content);
      setPageable(juzgadosData.page);
    } catch (error) {
      console.error('Error cargando juzgados:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadJuzgados();
  }, [getToken, page, rowsPerPage]);

  const handleDelete = (id) => {
    setDialogMessage(`El registro seleccionado será permanentemente eliminado del sistema.`);
    setItemToDelete(id);
    setDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    const token = getToken();
    try {
      await deleteJuzgado(token, itemToDelete);
      setJuzgados(juzgados.filter(juzgado => juzgado.id !== itemToDelete));
      setToastMessage('Registro eliminado con éxito');
      setToastType('success');
    } catch (error) {
      console.error('Error eliminando registro:', error);
      setToastMessage('El registro no fue eliminado');
      setToastType('error');
    }
    setToastOpen(true);
    setDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseToast = () => {
    setToastOpen(false);
  };

  const handleRefresh = (item) => {
    setSearchQuery("");
    loadJuzgados();
  };

  const handleEditJuzgado = (juzgado) => {
    navigate('/api/core/juzgados/editar_juzgado', { state: { juzgado } });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  }

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      loadJuzgados();
    }
  };

  return (
    <div>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Link to="/api/core/juzgados/crear_juzgado">
            <ButtonAdd ariaLabel="añadir nuevo juzgado" />
          </Link>
          &nbsp;
          <RefreshButton onClick={handleRefresh} />
        </Box>
        <Box flexBasis="300px" ml={2}>
          <SearchInput
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Buscar..."
            ariaLabel="Campo de búsqueda para juzgados"
            onKeyDown={handleSearchKeyDown}
          />
        </Box>
      </Box>
      <Box>
        {loading ?
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          // height="50vh"  
          >
            <Progress />
          </Box>
          : (
            <JuzgadosTable
              juzgados={juzgados}
              onDelete={handleDelete}
              onEdit={handleEditJuzgado}
              pageable={pageable}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
      </Box>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={dialogMessage}
      />

      <ToastAlertDelete
        open={toastOpen}
        onClose={handleCloseToast}
        message={toastMessage}
        type={toastType}
      />
    </div>
  );
};

export default Juzgados;
