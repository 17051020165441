import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Tooltip,
  styled,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const BloqueTable = ({
  bloques,
  onDelete,
  onEdit,
  pageable,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className='table-header'>Hora Inicial</TableCell>
                <TableCell className='table-header'>Hora Final</TableCell>
                <TableCell className='table-header'>Estado</TableCell>
                <TableCell className='table-header'>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bloques
                .map((item) => (
                  <StyledTableRow
                    key={item.id}
                    onClick={() => onEdit(item)}
                    hover
                    style={{ cursor: 'pointer' }}>
                    <TableCell>{item.horaInicial}</TableCell>
                    <TableCell>{item.horaFinal}</TableCell>
                    <TableCell>
                      {item.estado === "ACTIVE" ? <span>Activo</span> : <span>Inactivo</span>}
                    </TableCell>
                    <TableCell align="left">
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Eliminar">
                          <DeleteIcon className='icon-color'
                            onClick={(event) => {
                              event.stopPropagation();
                              onDelete(item.id, item.nombre);
                            }}
                          >
                          </DeleteIcon>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </StyledTableRow>
                ))}
              {bloques.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No hay registros disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default BloqueTable;
