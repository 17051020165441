import axios from 'axios';

// Función para obtener todas las personas desde la API
export const fetchPersonas = async (token, page = 0, size = 25, sort = '') => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/personas`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        sort,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al listar personas:', error);
    throw error;
  }
};

export const addPersona = async (token, nuevaPersona) => {
  try {
    const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/core/personas`, nuevaPersona, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al agregar persona:', error);
    throw error;
  }
};

export const updatePersona = async (token, persona) => {
  console.log('Datos enviando para actualizar: ', persona);
  try {
    const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/core/personas`, persona, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    });
    return response.data; // Devuelve la respuesta de la API
  } catch (error) {
    console.error('Error al actualizar persona:', error);
    throw error;
  }
};

export const deletePersonas = async (token, psid) => {
  try {
    await axios.delete(process.env.REACT_APP_TRIALS_API_URL + `/api/core/personas/${psid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Error al eliminar persona:', error);
    throw error;
  }
}

export const fetchPersonaById = async (token, id) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/personas/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener una persona:', error);
    throw error;
  }
};

export const fetchPersonaByCurp = async (token, curp) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/personas/curp/${curp}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener una persona:', error);
    throw error;
  }
};

export const fetchAllJueces = async (token, juzgadoId) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/personas/jueces/${juzgadoId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener jueces:', error);
    throw error;
  }
};

export const fetchAllCentroTrabajo = async (token) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/personas/centrostrabajo`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    const data = response.data;
    if (Array.isArray(data)) {
      return data;
    } else if (data?.content && Array.isArray(data.content)) {
      return data.content;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error al listar los Centros de Trabajo', error);
    throw error;
  }
};