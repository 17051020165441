import React, { useEffect, useState, useContext } from 'react';
import { Box } from "@mui/material";
import CarpetaTable from "./Grid/CarpetaTable";
import KeycloakConf from '../../api/keycloakConf';
import ConfirmationDialog from "../../components/Alert/ConfirmationDialog";
import ToastAlertDelete from "../../components/Alert/ToastAlertDelete/ToastAlertDelete";
import RefreshButton from "../../components/Button/RefreshButton";
import SearchInput from "../../components/Search/SearchInput";
import { findAllHistorialRegistroOficialias } from './CarpetaService';

const Carpeta = () => {
  const [carpetaData, setCarpetaData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const { getToken } = useContext(KeycloakConf);
  const [pageable, setPageable] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [isDigitalizacion, setIsDigitalizacion] = useState(false);

  useEffect(() => {
    loadCarpetaData();
   
  }, [page, rowsPerPage, searchQuery]);

  const loadCarpetaData = async () => {
    setLoading(true);
    const token = getToken();
    try {
      const data = await findAllHistorialRegistroOficialias(token, page, rowsPerPage, searchQuery);
      console.log('Datos recibidos de la API:', data);
      setCarpetaData(data.content || []);
      setPageable(data.page || {});
    } catch (error) {
      console.error('Error al cargar datos de la carpeta:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); 
  };

  const handleRefresh = () => {
    setSearchQuery(''); 
    setPage(0);
    loadCarpetaData(); 
  };

  return (
    <>
      {!isDigitalizacion && (
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <RefreshButton onClick={handleRefresh} />
          </Box>
          <Box flexBasis="300px" ml={2}>
            <SearchInput
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Búsqueda"
              ariaLabel="Campo de búsqueda para carpeta"
              tooltipTitle="Escriba para buscar"
            />
          </Box>
        </Box>
      )}

      <Box>
        {loading ? <p>Cargando...</p> : (
          <CarpetaTable
            data={carpetaData}
            token={getToken()}
            setIsDigitalizacion={setIsDigitalizacion}
            loadCarpetaData={loadCarpetaData}
            pageable={pageable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </Box>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        message={dialogMessage}
      />

      <ToastAlertDelete
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
        type={toastType}
      />
    </>
  );
};

export default Carpeta;
