import React, { useState, useContext, useEffect } from 'react';
import {
  Container,
  TextField,
  Tabs,
  Tab,
  IconButton,
  Typography,
  Grid,
  Paper,
  Button,
  Box
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { addDocumento, getSello } from '../DemandaService';
import KeycloakConf from '../../../api/keycloakConf';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateFields } from '../../../components/Alert/ValidationRequired/validationRequired';
import { onlyNames, onlyAlphaNumericSpace } from '../../../components/Caracteres/caracteresEspeciales';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertValidation } from '../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import DeleteIcon from '@mui/icons-material/Delete';

export default function FormDemanda({ token, isEditable, handleEditDemanda, demandaData }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const { juicioId, nombreJuicio, materia } = location.state || {};

  const [actorTab, setActorTab] = useState(0);
  const [demandadoTab, setDemandadoTab] = useState(0);
  const [anexos, setAnexos] = useState([]);
  const [tipoJuicioId, setTipoJuicioId] = useState(juicioId);
  const { getToken } = useContext(KeycloakConf);
  const [modalOpen, setModalOpen] = useState(false);
  const [razonEdicion, setRazonEdicion] = useState('');
  const [isEditAnexos, setIsEditAnexos] = useState(false);

  const [actorFisica, setActorFisica] = useState({
    nombreActor: '',
    apellidoPaternoActor: '',
    apellidoMaternoActor: '',
    pseudonimoActor: '',
  });

  const [actorMoral, setActorMoral] = useState({
    nombreActorMoral: '',
  });

  const [demandadoFisica, setDemandadoFisica] = useState({
    nombreDemandado: '',
    apellidoPaternoDemandado: '',
    apellidoMaternoDemandado: '',
    pseudonimoDemandado: '',
  });

  const [demandadoMoral, setDemandadoMoral] = useState({
    nombreDemandadoMoral: '',
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (demandaData) {
      // Si la demandaData tiene información, actualiza los estados , este solo es para editar ya que solo trae los valores al form 
      if (demandaData.actor.tipoPersona === 'fisica') {
        setActorFisica({
          nombreActor: demandaData.actor.nombre,
          apellidoPaternoActor: demandaData.actor.apellidoPaterno,
          apellidoMaternoActor: demandaData.actor.apellidoMaterno,
          pseudonimoActor: demandaData.actor.pseudonimo,
        });
        setActorTab(0);
      } else if (demandaData.actor.tipoPersona === 'moral') {
        setActorMoral({
          nombreActorMoral: demandaData.actor.nombre,
        });
        setActorTab(1);
      }

      if (demandaData.demandado.tipoPersona === 'fisica') {
        setDemandadoFisica({
          nombreDemandado: demandaData.demandado.nombre,
          apellidoPaternoDemandado: demandaData.demandado.apellidoPaterno,
          apellidoMaternoDemandado: demandaData.demandado.apellidoMaterno,
          pseudonimoDemandado: demandaData.demandado.pseudonimo,
        });
        setDemandadoTab(0);
      } else if (demandaData.demandado.tipoPersona === 'moral') {
        setDemandadoMoral({
          nombreDemandadoMoral: demandaData.demandado.nombre,
        });
        setDemandadoTab(1);
      }

      setAnexos(demandaData.anexos || []);
    }
  }, [demandaData]);
  // Verifica si estamos en la ruta para poder editar 
  const isEditRoute = location.pathname === '/api/workflow/demanda/edit';


  const isDemandaDataComplete = demandaData && demandaData.actor && demandaData.demandado;

  const handleActorTabChange = (event, newValue) => {
    setActorTab(newValue);
  };

  const handleDemandadoTabChange = (event, newValue) => {
    setDemandadoTab(newValue);
  };

  const handleAddAnexo = () => {
    setAnexos([...anexos, '']);
  };

  const handleAnexoChange = (index, value) => {
    const newValue = onlyAlphaNumericSpace(value);
    const newAnexos = [...anexos];
    newAnexos[index] = newValue;
    setAnexos(newAnexos);
  };

  const fieldsMap  = {
    'nombreActor': onlyNames,
    'apellidoPaternoActor': onlyNames,
    'apellidoMaternoActor': onlyNames,
    'pseudonimoActor': onlyNames,
    'nombreActorMoral': onlyNames,
    'nombreDemandado': onlyNames,
    'apellidoPaternoDemandado': onlyNames,
    'apellidoMaternoDemandado': onlyNames,
    'pseudonimoDemandado': onlyNames,
    'nombreDemandadoMoral': onlyNames
  };

  const handleInputChange = (e, setStateFunction) => {
    let { name, value } = e.target;

    const caracterValue = (fieldsMap[name] || ((val) => val))(value);

    setStateFunction(prevState => ({
      ...prevState,
      [name]: caracterValue,
    }));
  };

  const validateForm = () => {
    const actorRules = actorTab === 0 ? {
      nombreActor: { required: true },
      apellidoPaternoActor: { required: true },
    } : {
      nombreActorMoral: { required: true },
    };

    const demandadoRules = demandadoTab === 0 ? {
      nombreDemandado: { required: true },
      apellidoPaternoDemandado: { required: true },
    } : {
      nombreDemandadoMoral: { required: true },
    };

    const actorErrors = validateFields(actorTab === 0 ? actorFisica : actorMoral, actorRules, showAlertValidate);
    const demandadoErrors = validateFields(demandadoTab === 0 ? demandadoFisica : demandadoMoral, demandadoRules, showAlertValidate);
   
    const edicionErrors = isEditRoute
      ? validateFields({ razonEdicion }, { razonEdicion: { required: true } }, showAlertValidate)
      : {};

    setErrors({
      ...actorErrors,
      ...demandadoErrors,
      ...edicionErrors,
    });

 
    return (
      Object.keys(actorErrors).length === 0 &&
      Object.keys(demandadoErrors).length === 0 &&
      Object.keys(edicionErrors).length === 0
    );
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!validateForm()) return;
    
  //   setModalOpen(true);
   
  // };

  // const handleSubmitEdicion = () => {
  //   if (!validateForm()) return;
   
  //   setModalOpen(true);
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
  
   
    setIsEditAnexos(false);
    setModalOpen(true);
  };
  
  const handleSubmitEdicion = () => {
    if (!validateForm()) return;
  

    setIsEditAnexos(true);
    setModalOpen(true);
  };
  

  const handleConfirmEditAnexos = () => {
  
    setModalOpen(false);
    handleEditDemanda(anexos, razonEdicion);

    navigate('/api/bandeja/entrada');
  };




  const handleButtonClick = (e) => {
    e.preventDefault();

    if (isEditRoute) {
      handleSubmitEdicion(); // Si estamos en la ruta para editar
    } else {
      handleSubmit(e); // Si no estamos en la ruta y estamos para crear una nueva 
    }
  };

  const handleConfirm = async () => {
    const token = await getToken();

    // Transformar los datos al formato deseado
    const nuevoDocumento = {
      actor: actorTab === 0 ? {
        nombre: actorFisica.nombreActor,
        apellidoPaterno: actorFisica.apellidoPaternoActor,
        apellidoMaterno: actorFisica.apellidoMaternoActor,
        pseudonimo: actorFisica.pseudonimoActor,
        tipoPersona: 'fisica',
        tipoParte: 1,
      } : {
        nombre: actorMoral.nombreActorMoral,
        tipoPersona: 'moral',
        tipoParte: 1,
      },
      demandado: demandadoTab === 0 ? {
        nombre: demandadoFisica.nombreDemandado,
        apellidoPaterno: demandadoFisica.apellidoPaternoDemandado,
        apellidoMaterno: demandadoFisica.apellidoMaternoDemandado,
        pseudonimo: demandadoFisica.pseudonimoDemandado,
        tipoPersona: 'fisica',
        tipoParte: 2,
      } : {
        nombre: demandadoMoral.nombreDemandadoMoral,
        tipoPersona: 'moral',
        tipoParte: 2,
      },
      anexos: anexos,
      tipoJuicioId: tipoJuicioId,
    };

    try {
      let documento = await addDocumento(token, nuevoDocumento);

      navigate('/api/workflow/demanda');
      showSnackbar('Demanda generada con exito', 'success');
      const sello = await getSello(token, documento.id);
      var blob = new Blob([sello], { type: 'application/pdf' });
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.target = 'sello.pdf'
      link.click()
    } catch (error) {
      console.error('Error al agregar el documento:', error);
      showSnackbar('¡Algo salió mal!', 'error');
    }

    setModalOpen(false);
  };

  const handleClose = () => {

    if (isEditRoute) {
      navigate('/api/bandeja/entrada');
    } else {
      navigate('/api/workflow/demanda');
     
    }
  };


  const handleRemoveAnexo = (index) => {
    const newAnexos = anexos.filter((_, i) => i !== index);
    setAnexos(newAnexos);
  };
  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
       
        <Button type="submit" variant="contained" className="button-save" onClick={handleButtonClick} >
          Guardar
        </Button>
        <Button variant="contained" className="button-close" onClick={handleClose} sx={{ ml: 2 }}>
          Cerrar
        </Button>
      </Box>

      <ConfirmationModal
        // open={modalOpen}
        open={modalOpen && !isEditAnexos}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        title="¿Generar demanda?"
        message="Se generará una nueva demanda en el sistema."
        confirmationButton="Si, generar"
        closeButton="No, cancelar"
      />
      <ConfirmationModal
        // open={modalOpen}
        open={modalOpen && isEditAnexos}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirmEditAnexos} 
        title="¿Actualizar anexos?"
        message="Se actualizarán los anexos con el motivo de la edición en el sistema."
        confirmationButton="Sí, actualizar"
        closeButton="No, cancelar"
      />

      <Paper elevation={9} sx={{ padding: 4, flex: 1, overflowY: 'auto' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h6">Actor</Typography>
            </Grid>

            <Grid item xs={12}>
              <Tabs value={actorTab} onChange={handleActorTabChange} variant="fullWidth">
                <Tab label="Persona física" />
                <Tab label="Persona moral" />
              </Tabs>
            </Grid>

            {actorTab === 0 && (
              <>
                <Grid item xs={6}>
                  <TextField
                    label="Nombre(s)"
                    name="nombreActor"
                    fullWidth
                    value={actorFisica.nombreActor}
                    onChange={(e) => handleInputChange(e, setActorFisica, 'nombreActor')}
                    error={!!errors.nombreActor}
                    helperText={errors.nombreActor}
                    disabled={isEditable}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Apellido paterno"
                    name="apellidoPaternoActor"
                    fullWidth
                    value={actorFisica.apellidoPaternoActor}
                    onChange={(e) => handleInputChange(e, setActorFisica, 'apellidoPaternoActor')}
                    error={!!errors.apellidoPaternoActor}
                    helperText={errors.apellidoPaternoActor}
                    disabled={isEditable}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Apellido materno (Opcional)"
                    name="apellidoMaternoActor"
                    fullWidth
                    value={actorFisica.apellidoMaternoActor}
                    onChange={(e) => handleInputChange(e, setActorFisica, 'apellidoMaternoActor')}
                    disabled={isEditable}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Pseudónimo (Opcional)"
                    name="pseudonimoActor"
                    fullWidth
                    value={actorFisica.pseudonimoActor}
                    onChange={(e) => handleInputChange(e, setActorFisica, 'pseudonimoActor')}
                    disabled={isEditable}
                  />
                </Grid>
              </>
            )}

            {actorTab === 1 && (
              <Grid item xs={12}>
                <TextField
                  label="Nombre"
                  name="nombreActorMoral"
                  fullWidth
                  value={actorMoral.nombreActorMoral}
                  onChange={(e) => handleInputChange(e, setActorMoral, 'nombreActorMoral')}
                  error={!!errors.nombreActorMoral}
                  helperText={errors.nombreActorMoral}
                  disabled={isEditable}
                />
              </Grid>
            )}

            <Grid item xs={12} mt={4}>
              <Typography variant="h6">Demandado</Typography>
            </Grid>

            <Grid item xs={12}>
              <Tabs value={demandadoTab} onChange={handleDemandadoTabChange} variant="fullWidth">
                <Tab label="Persona física" />
                <Tab label="Persona moral" />
              </Tabs>
            </Grid>

            {demandadoTab === 0 && (
              <>
                <Grid item xs={6}>
                  <TextField
                    label="Nombre(s)"
                    name="nombreDemandado"
                    fullWidth
                    value={demandadoFisica.nombreDemandado}
                    onChange={(e) => handleInputChange(e, setDemandadoFisica, 'nombreDemandado')}
                    error={!!errors.nombreDemandado}
                    helperText={errors.nombreDemandado}
                    disabled={isEditable}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Apellido paterno"
                    name="apellidoPaternoDemandado"
                    fullWidth
                    value={demandadoFisica.apellidoPaternoDemandado}
                    onChange={(e) => handleInputChange(e, setDemandadoFisica, 'apellidoPaternoDemandado')}
                    error={!!errors.apellidoPaternoDemandado}
                    helperText={errors.apellidoPaternoDemandado}
                    disabled={isEditable}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Apellido materno (Opcional)"
                    name="apellidoMaternoDemandado"
                    fullWidth
                    value={demandadoFisica.apellidoMaternoDemandado}
                    onChange={(e) => handleInputChange(e, setDemandadoFisica, 'apellidoMaternoDemandado')}
                    disabled={isEditable}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Pseudónimo (Opcional)"
                    name="pseudonimoDemandado"
                    fullWidth
                    value={demandadoFisica.pseudonimoDemandado}
                    onChange={(e) => handleInputChange(e, setDemandadoFisica, 'pseudonimoDemandado')}
                    disabled={isEditable}
                  />
                </Grid>
              </>
            )}

            {demandadoTab === 1 && (
              <Grid item xs={12}>
                <TextField
                  label="Nombre"
                  name="nombreDemandadoMoral"
                  fullWidth
                  value={demandadoMoral.nombreDemandadoMoral}
                  onChange={(e) => handleInputChange(e, setDemandadoMoral, 'nombreDemandadoMoral')}
                  error={!!errors.nombreDemandadoMoral}
                  helperText={errors.nombreDemandadoMoral}
                  disabled={isEditable}
                />
              </Grid>
            )}

            {isEditRoute && isDemandaDataComplete && (

              <Grid item xs={12}>
                <Typography variant="h6">Motivo edición</Typography>
                <TextField

                  multiline
                  placeholder='Motivo edición'
                  rows={4}
                  fullWidth
                  variant="outlined"
                  value={razonEdicion}

                  onChange={(e) => {
                    setRazonEdicion(e.target.value);


                    if (errors.razonEdicion) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        razonEdicion: '',
                      }));
                    }
                  }}
                  error={!!errors.razonEdicion}
                  helperText={errors.razonEdicion}
                  required={isEditRoute}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h6">Anexos</Typography>
              {anexos.map((anexo, index) => (
                <Grid container alignItems="center" key={index} sx={{ mt: 2 }}>
                  <Grid item xs>
                    <TextField
                      label={`Anexo ${index + 1}`}
                      fullWidth
                      value={anexo}
                      onChange={(e) => handleAnexoChange(index, e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => handleRemoveAnexo(index)}
                      color="error" 
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
          

              ))}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                variant="contained"
                onClick={handleAddAnexo}
              >
                Agregar Anexo
              </Button>
            </Grid>


          </Grid>
        </form>
      </Paper>
    </Container>
  );
}
