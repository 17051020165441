import { Box, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
import AddTaskIcon from '@mui/icons-material/AddTask';
import UndoIcon from '@mui/icons-material/Undo';
import ChatIcon from '@mui/icons-material/Chat';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const RecepcionTable = ({
    bandejas,
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage
}

) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>Folio</TableCell>
                                <TableCell className='table-header'>No. Expediente</TableCell>
                                <TableCell className='table-header'>Tipo de entrada</TableCell>
                                <TableCell className='table-header'>Origen</TableCell>
                                <TableCell className='table-header'>Concepto</TableCell>
                                <TableCell className='table-header'>Fecha y hora envío</TableCell>
                                <TableCell className='table-header'>Acciones rápidas</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bandejas.map((item) => (
                                <StyledTableRow
                                    key={item.id}
                                    hover
                                    style={{ cursor: 'pointer' }}>
                                    <TableCell>{item.folio}</TableCell>
                                    <TableCell>{item.expediente}</TableCell>
                                    <TableCell>{item.tipoEntrada}</TableCell>
                                    <TableCell>{item.origen}</TableCell>
                                    <TableCell>{item.concepto}</TableCell>
                                    <TableCell> {new Date(item.fechaHoraEnvio).toLocaleString()} </TableCell>
                                    <TableCell>
                                        <Tooltip title="Devolver">
                                            <UndoIcon className='icon-color' />
                                        </Tooltip>
                                        <Tooltip title="Recepción">
                                            <AddTaskIcon className='icon-color' />
                                        </Tooltip>
                                        <Tooltip title="Observaciones">
                                            <ChatIcon className='icon-color' />
                                        </Tooltip>
                                    </TableCell>

                                </StyledTableRow>
                            ))}
                            {bandejas.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
        </Box>
    )
};

export default RecepcionTable;