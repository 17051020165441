import { createBrowserRouter, redirect } from 'react-router-dom';
import Layout from '../../layouts/Layout';
import Juzgados from '../../pages/Juzgados/Juzgados';
import Materias from '../../pages/Materias/Materias';
import ListaValor from '../../pages/ListaValor/ListaValor';
import Home from '../../pages/Home/Home';
import Personas from '../../pages/Personas/Personas';
import Oficialias from '../../pages/Oficialias/Oficialias';
import Salas from '../../pages/Salas/Salas';
import Digitalizacion from '../../pages/Bandeja/AccionesRapidas/Digitalizacion/Digitalizacion' 
import Bandeja from '../../pages/Bandeja/Bandeja';
import BandejaSalida from '../../pages/BandejaSalida/BandejaSalida';
import Institucion from '../../pages/Instituciones/Institucion'
import Bloque from '../../pages/Bloques/Bloque';

//Formularios
import ProtectedRoute from './ProtectedRoute';
import CreateJuzgado from '../../pages/Juzgados/CRUD/CreateJuzgado';
import EditJuzgado from '../../pages/Juzgados/CRUD/EditJuzgado';
import CreateListaValor from '../../pages/ListaValor/CRUD/CreateListaValor';
import EditListaValor from '../../pages/ListaValor/CRUD/EditListaValor';
import CreateOficialia from '../../pages/Oficialias/CRUD/CreateOficialia';
import EditOficialia from '../../pages/Oficialias/CRUD/EditOficialia';
import Sede from '../../pages/Sedes/Sede';
import CreateSede from '../../pages/Sedes/Form/CreateSede';
import EditSede from '../../pages/Sedes/Form/EditSede';
import Demanda from '../../pages/Demanda/Demanda';
import Promocion from '../../pages/Promocion/Promocion';
import FormDemanda from '../../pages/Demanda/Form/FormDemanda';
import FormDemandaFamiliar from '../../pages/Demanda/Form/FormFamiliar/FormDemandaFamiliar';
import EditPersona from '../../pages/Personas/CRUD/Formulario/EditPersona';
import CreateSala from '../../pages/Salas/CRUD/CreateSala';
import EditSala from '../../pages/Salas/CRUD/EditSala';
import EditFormDemanda from '../../pages/Demanda/Form/EditFormDemanda';

import CreateInstitucion from '../../pages/Instituciones/Form/CreateInstitucion';
import EditInstitucion from '../../pages/Instituciones/Form/EditInstitucion';
import CreateBloque from '../../pages/Bloques/Form/CreateBloque';
import EditBloque from '../../pages/Bloques/Form/EditBloque';
import FormularioExhortos from '../../pages/Exhorto/Form/FormExhorto';

import Carpeta from '../../pages/Carpeta/Carpeta';
import Apelacion from '../../pages/Apelacion/Apeleacion';
import Recepcion from '../../pages/Bandeja/Recepcion/RecepcionList';
import RecepcionList from '../../pages/Bandeja/Recepcion/RecepcionList';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        loader: () => redirect('/home'), // Redirige de '/' a '/home' automáticamente
      },
      {
        path: '/home', // Ruta para Home
        element:
          <Home />, // Componente que manejará esta ruta


      },
      {
        path: '/api/core/juzgados', // Ruta para juzgados
        element: (
          <ProtectedRoute>
            <Juzgados />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/oficialias',
        element: (
          <ProtectedRoute>
            <Oficialias />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/recursos', // Ruta para recursos 
        element: <Home />,
      },
      {
        path: '/api/core/personas',
        element: (
          <ProtectedRoute>
            <Personas />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/personas/edit',
        element: (
          <ProtectedRoute>
            <EditPersona />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/materias',
        element: (
          <ProtectedRoute>
            <Materias />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/oficialias/crear_oficialia',
        element: (
          <ProtectedRoute>
            <CreateOficialia />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/oficialias/editar_oficialia', // La ruta para editar un juzgado
        element: (
          <ProtectedRoute>
            <EditOficialia />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/juzgados/crear_juzgado',
        element: (
          <ProtectedRoute>
            <CreateJuzgado />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/juzgados/editar_juzgado', // La ruta para editar un juzgado
        element: (
          <ProtectedRoute>
            <EditJuzgado />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/listavalor',
        element: (
          <ProtectedRoute>
            <ListaValor />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/listavalor/add',
        element: (
          <ProtectedRoute>
            <CreateListaValor />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/listavalor/edit',
        element: (
          <ProtectedRoute>
            <EditListaValor />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/sedes',
        element: (
          <ProtectedRoute>
            <Sede />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/sedes/add',
        element: (
          <ProtectedRoute>
            <CreateSede />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/sedes/edit',
        element: (
          <ProtectedRoute>
            <EditSede />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/workflow/demanda',
        element: (
          <ProtectedRoute>
            <Demanda/>
          </ProtectedRoute>
        ),
      },

      {
        path: '/api/workflow/exhorto',
        element: (
          <ProtectedRoute>
            <FormularioExhortos/>
          </ProtectedRoute>
        ),
      },

      {
        path: '/api/workflow/demanda/add',
        element: (
          <ProtectedRoute>
          <FormDemanda/>
          </ProtectedRoute>
        ),
      },

      {
        path: '/api/workflow/demanda/edit',
        element: (
          <ProtectedRoute>
           <EditFormDemanda/>
          </ProtectedRoute>
        ),
      },


      {
        path: '/api/workflow/demanda/familiar',
        element: (
          <ProtectedRoute>
           <FormDemandaFamiliar/>
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/workflow/promocion',
        element: (
          <ProtectedRoute>
            <Promocion/>
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/salas',
        element: (
          <ProtectedRoute>
            <Salas />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/salas/editar_salas',
        element: (
          <ProtectedRoute>
            <EditSala />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/salas/crear_salas',
        element: (
          <ProtectedRoute>
            <CreateSala />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/digitalizacion',
        element: (
          <ProtectedRoute>
            <Digitalizacion />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/bandeja/entrada/',
        element: (
          <ProtectedRoute>
            <Bandeja />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/salida/',
        element: (
          <ProtectedRoute>
            <BandejaSalida />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/core/instituciones/',
        element: (
          <ProtectedRoute>
            <Institucion />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/core/instituciones/editar_institucion',
        element: (
          <ProtectedRoute>
            <EditInstitucion />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/instituciones/crear_institucion',
        element: (
          <ProtectedRoute>
            <CreateInstitucion />
          </ProtectedRoute>
        ),
      },
      {

        path: '/api/core/bloques',
        element: (
          <ProtectedRoute>
            <Bloque />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/core/bloques/add',
        element: (
          <ProtectedRoute>
            <CreateBloque />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/bloques/edit',
        element: (
          <ProtectedRoute>
            <EditBloque />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/bandeja/historial',
        element: (
          <ProtectedRoute>
            <Carpeta />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/workflow/apelacion',
        element: (
          <ProtectedRoute>
            <Apelacion />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/bandeja/recepcion',
        element: (
          <ProtectedRoute>
            <RecepcionList />
          </ProtectedRoute>
        )
      },
      /* se comenta ruta de expediente.
      {
        path: '/api/bandeja/expediente/',
        element: (
          <ProtectedRoute>
            <Asignado />
          </ProtectedRoute>
        )
      },
       */
      

    ],
  },
]);
export default router;
