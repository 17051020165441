import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import { validateFields } from '../../components/Alert/ValidationRequired/validationRequired';
import keycloakConf from "../../api/keycloakConf";
import FormApelacion from "./Form/FormApelacion";
import FormExpedienteJuzgado from "../../layouts/FormExpedienteJuzgado/FormExpedienteJuzgado";
import { useToastAlert } from '../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertValidation } from '../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchCarpetaActorDemandado, fetchPersonasByCarpetaId } from '../../shared/services/CarpetaService';
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { createApelacion } from "./ApelacionService"; 
import { getSello } from '../Demanda/DemandaService';


export default function Apelacion() {
  const { getToken } = useContext(keycloakConf);
  const [token, setToken] = useState({});
  const { showSnackbar } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const [errorMessages, setErrorMessages] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [bandValidate, setBandValidate] = useState(false);
  const [anexos, setAnexos] = useState([]);

  const [selectedActorCheckboxes, setSelectedActorCheckboxes] = useState([]);
  const [selectedDemandadoCheckboxes, setSelectedDemandadoCheckboxes] = useState([]);
  const [otroNombreActor, setOtroNombreActor] = useState("");
  const [otroNombreDemandado, setOtroNombreDemandado] = useState("");

  const [formData, setFormData] = useState({
    expediente: '',
    año: '',
    juzgado: null
  });
  const [carpeta, setCarpeta] = useState({
    idCarpeta: 0,
    actor: '',
    demandado: ''
  });
  const [personas, setPersonas] = useState([]);

  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        const token = await getToken();
        setToken(token);
      } catch (error) {
        console.error("Error al obtener el token:", error);
      }
    };

    obtenerDatos();
  }, [getToken]);

  const handleValidateFEJ = async (e) => {
    const rules = {
      expediente: { required: true },
      año: { required: true },
      juzgado: { required: true },
    };
    const errors = validateFields(formData, rules, showAlertValidate);
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }
    searchInfo();
    setErrorMessages({});
  };

  const searchInfo = async () => {
    let response;
    try {
      const data = {
        "numExpediente": formData.expediente,
        "year": Number(formData.año),
        "idJuzgado": formData.juzgado.id
      };
      response = await fetchCarpetaActorDemandado(token, data);
      setCarpeta({
        idCarpeta: response.idCarpeta,
        actor: response.actor,
        demandado: response.demandado
      });

      setBandValidate(true);
    } catch (error) {
      setBandValidate(false);
      showSnackbar('No existe expediente relacionado con la información ingresada', 'error');
    }

    try {
      const data = await fetchPersonasByCarpetaId(token, response.idCarpeta);
      setPersonas(data);
    } catch (error) {
      console.error('Error al obtener personas:', error);
    }

  };

  const clearFields = () => {
    setFormData({
      expediente: '',
      año: '',
      juzgado: null
    });
    setCarpeta({
      idCarpeta: 0,
      actor: '',
      demandado: ''
    });
    setPersonas([]);
    setAnexos([]);
    setOtroNombreActor("");
    setOtroNombreDemandado("");
    setSelectedActorCheckboxes([]);
    setSelectedDemandadoCheckboxes([]);
    setBandValidate(false);
  }; 

  const handleConfirm = async () => {
    const selectedActors = selectedActorCheckboxes.map((id) => {
      const persona = personas.find((p) => p.tiposPartesId === id);
      return {
        nombre: persona.nombre,
        apellidoPaterno: persona.apellidoPaterno,
        apellidoMaterno: persona.apellidoMaterno,
        pseudonimo: "",
        tipoPersona: "fisica",
        tipoPartes: id,
      };
    });

    const data = {
      carpetaId: carpeta.idCarpeta,
      apelacionPersonaRecords: selectedActors,
      anexos: anexos.map(anexo => ({ nombre: anexo })),
      otroNombreActor,
      otroNombreDemandado,
    };

    try {
      const result = await createApelacion(token, data);

      // const sello = await getSello(token, result.id);
      // var blob = new Blob([sello], { type: 'application/pdf' });
      // let link = document.createElement('a')
      // link.href = window.URL.createObjectURL(blob)
      // link.target = 'sello.pdf'
      // link.click()

      showSnackbar("Apelación generada con éxito", "success");
    } catch (error) {
      console.error("Error al enviar la apelación:", error);
      showSnackbar("¡Algo salió mal!", "error");
    }

    clearFields();
    setModalOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const invalidAnexos = anexos.filter(anexo => anexo.length < 3);
    if (invalidAnexos.length > 0) {
      showSnackbar("Todos los anexos deben tener al menos 3 caracteres.", "error");
      return;
    }
    if (selectedActorCheckboxes.length === 0 && selectedDemandadoCheckboxes.length === 0) {
      showSnackbar("Debe seleccionar al menos un actor o demandado.", "error");
      return;
    }

    setModalOpen(true);
    
  };

  return (
    <>
      <ConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        title="¿Generar Apelación?"
        message="Se generará una nueva apelación en el sistema, con la información proporcionada."
        confirmationButton="Sí, generar"
        closeButton="No, cancelar"
      />
      <Grid item xs={12} sm={6} md={6}>
        <FormExpedienteJuzgado 
          handleSubmit={handleSubmit}
          handleValidate={handleValidateFEJ}
          formData={formData}
          setFormData={setFormData}
          errorMessages={errorMessages}
        />
      </Grid>
      {bandValidate && (
        <FormApelacion 
          carpeta={carpeta}
          personas={personas}
          selectedActorCheckboxes={selectedActorCheckboxes}
          setSelectedActorCheckboxes={setSelectedActorCheckboxes}
          selectedDemandadoCheckboxes={selectedDemandadoCheckboxes}
          setSelectedDemandadoCheckboxes={setSelectedDemandadoCheckboxes}
          otroNombreActor={otroNombreActor}
          setOtroNombreActor={setOtroNombreActor}
          otroNombreDemandado={otroNombreDemandado}
          setOtroNombreDemandado={setOtroNombreDemandado}
          anexos={anexos}
          setAnexos={setAnexos}
        />
      )}
    </>
  );
}
