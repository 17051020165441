import { Box } from "@mui/material";

import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../../api/keycloakConf";
import { fetchList } from "./BandejaService";
import RecepcionTable from "./RecepcionTable";
import RefreshButton from "../../../components/Button/RefreshButton";
import SearchInput from "../../../components/Search/SearchInput";

const RecepcionList = () => {
    const [bandejas, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { getToken } = useContext(keycloakConf);
    const [loading, setLoading] = useState(true);
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    useEffect(() => {
        loadBandejas();
    }, [getToken, page, rowsPerPage]);

    const loadBandejas = async () => {
        setLoading(true);
        const token = getToken();
        try {
            const bandejaData = await fetchList(token, page, rowsPerPage, '', searchQuery);
            setData(bandejaData.content);
            setPageable(bandejaData.page)
        } catch (error) {
            console.error('Error cargando registros:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = () => {
        setSearchQuery('');
        setPage(0);
        loadBandejas();
    };

    return (
        <>
            <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <RefreshButton onClick={handleRefresh} />
                </Box>
                <Box flexBasis="300px" ml={2}>
                    <SearchInput
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Buscar "
                        tooltipTitle="Escriba para buscar"
                        onKeyDown={(e) => e.key === 'Enter' && loadBandejas()}
                    />
                </Box>
            </Box>
            <Box>
                {loading ? <p>Cargando ... </p> : (

                    <RecepcionTable
                        bandejas={bandejas}
                        token={getToken()}
                        loadBandejas={loadBandejas}
                        pageable={pageable}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />

                )}
            </Box>
        </>
    )
};

export default RecepcionList;