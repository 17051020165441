import React, { useState, useContext, useEffect } from "react";
import {
  TextField, Divider, IconButton, Select, MenuItem, FormHelperText,
  FormControl, Typography, Grid, Button, Box, InputLabel } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeycloakConf from "../../../api/keycloakConf";
import { useLocation, useNavigate } from "react-router-dom";
import { onlyAlphaNumericSpace } from "../../../components/Caracteres/caracteresEspeciales";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { useToastAlertValidation } from "../../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import DeleteIcon from "@mui/icons-material/Delete";

export default function FormPromocion({
  carpeta,
  tipoPromocion,
  setTipoPromocion,
  errorMessages,
  anexos,
  setAnexos
}) {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const location = useLocation();
  const handleAddAnexo = () => {
    setAnexos([...anexos, ""]);
    setIsButtonClicked(true);
  };

  const handleAnexoChange = (index, value) => {
    const newValue = onlyAlphaNumericSpace(value);
    const newAnexos = [...anexos];
    newAnexos[index] = newValue;
    setAnexos(newAnexos);
  };

  const handleRemoveAnexo = (index) => {
    const newAnexos = anexos.filter((_, i) => i !== index);
    setAnexos(newAnexos);

    if (newAnexos.length === 0) {
      setIsButtonClicked(false);
    }
  };

  const handleChange = (event) => {
    setTipoPromocion({tipoPromocion: event.target.value});
  };

  return (
    <div>
      <form>
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6">Actor: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={10}>
            <Typography variant="h6" style={{ color: 'gray' }}>{carpeta.actor}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="h6">Demandado: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={10}>
            <Typography variant="h6" style={{ color: 'gray' }}>{carpeta.demandado || ''}</Typography>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2, marginBottom: 2}}>
            <Divider/>
        </Box>

        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant="h6" sx={{ minWidth: '150px' }}>Tipo promoción</Typography>
              <FormControl fullWidth variant="outlined" error={!!errorMessages.tipoPromocion}>
                <InputLabel id="select-tipo-promocion-label"></InputLabel>
                <Select
                  labelId="select-tipo-promocion-label"
                  name="tipoPromocion"
                  value={tipoPromocion.tipoPromocion}
                  onChange={handleChange}
                  label=""
                  error={!!errorMessages.tipoPromocion}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <span style={{ color: 'gray' }}>Selecciona uno</span>;
                    }
                    return selected === "ESCRITO" ? "Escrito" : "Oficio"; 
                  }}
                >
                  <MenuItem value="ESCRITO">Escrito</MenuItem>
                  <MenuItem value="OFICIO">Oficio</MenuItem>
                </Select>
                {errorMessages.tipoPromocion && <FormHelperText>{errorMessages.tipoPromocion}</FormHelperText>}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant="h6" sx={{ minWidth: '150px' }}>Anexos</Typography>
              <Box sx={{ flexGrow: 2 }}>
                {anexos.map((anexo, index) => (
                  <Grid container key={index} sx={{ mb: 1 }} alignItems="center">
                    <Grid item xs={11}>
                      <TextField
                        label={`Anexo ${index + 1}`}
                        fullWidth
                        value={anexo}
                        onChange={(e) => handleAnexoChange(index, e.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => handleRemoveAnexo(index)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Box>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-start" sx={{ mt: 1 }}>
                <Button
                  sx={{ ml: isButtonClicked ? 20.6 : 0 }}
                  startIcon={<AddCircleOutlineIcon />}
                  variant="contained"
                  onClick={handleAddAnexo}
                >
                  Agregar Anexo
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </form>
    </div>
  );
}
