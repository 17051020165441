import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Box, Collapse, ListItemButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material'; 

export default function DemandaTable({ datos, openMaterias, setOpenMaterias }) {
  const navigate = useNavigate();

  const handleCellClick = (nombreJuicio, materia, juicioId) => {
    const formDemanda = '/api/workflow/demanda/add';
    const formFamiliar = '/api/workflow/demanda/familiar';

    const materiaFamiliar = (materia === 'FAMILIAR' && nombreJuicio === 'Familiar (Tradicional)');
    const navigateForm = materiaFamiliar ? formDemanda : (materia === 'FAMILIAR' ? formFamiliar : formDemanda);

    navigate(navigateForm, {
      state: { juicioId, nombreJuicio, materia }
    });
  };

  const toggleMateria = (materia) => {
    setOpenMaterias(prevState => ({
      ...prevState,
      [materia]: !prevState[materia]
    }));
  };

  return (
    <Box mb={3}>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {datos.map(({ materia, juicios }) => (
              <React.Fragment key={materia}>
                <TableRow>
                  <TableCell colSpan={2} align="left">
                    <ListItemButton onClick={() => toggleMateria(materia)}>
                      <Typography variant="h6" component="p" sx={{ flexGrow: 1 }}>
                        {materia}
                      </Typography>
                      {openMaterias[materia] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2} style={{ padding: 0 }}>
                    <Collapse in={openMaterias[materia]} timeout="auto" unmountOnExit>
                      <Table>
                        <TableBody>
                          {juicios.map((juicio) => (
                            <TableRow
                              key={juicio.id}
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.100)',
                                  cursor: 'pointer',
                                },
                              }}
                              onClick={() => handleCellClick(juicio.nombre, materia, juicio.id)}
                            >
                              <TableCell>{juicio.nombre}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
