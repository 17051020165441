
import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Stack,
  Button,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';

const SedeTable = ({
  sedes,
  onDelete,
  onEdit,
  pageable,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className='table-header'>Nombre</TableCell>
                <TableCell className='table-header'>Estado</TableCell>
                <TableCell className='table-header'>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sedes
                .map((item) => (
                  <TableRow
                    key={item.id}
                    onClick={() => onEdit(item)}
                    hover
                    style={{ cursor: 'pointer' }}>
                    <TableCell>{item.nombre}, C.{item.domicilio.calle}, No.{item.domicilio.exterior}, Col:{item.domicilio.colonia}, {item.domicilio.estadoRepublica}, CP:{item.domicilio.codigoPostal}</TableCell>
                    <TableCell>
                      {item.estado === "ACTIVE" ? <span>Activo</span> : <span>Inactivo</span>}
                    </TableCell>
                    <TableCell align="left">
                      <Stack direction="row" spacing={1}>
                        <Tooltip title="Eliminar">
                          <DeleteIcon
                            className='icon-color'
                            onClick={(event) => {
                              event.stopPropagation();
                              onDelete(item.id, item.nombre);
                            }}
                          >
                          </DeleteIcon>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              {sedes.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No hay registros disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default SedeTable;
