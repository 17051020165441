import React, { useEffect, useState, useContext } from 'react';
import SalaTable from './CRUD/SalaTable';
import { fetchSalas, deleteSalas } from './SalasService';
import KeycloakConf from '../../api/keycloakConf';
import { Box } from '@mui/material';
import ToastAlertDelete from '../../components/Alert/ToastAlertDelete/ToastAlertDelete';
import ConfirmationDialog from '../../components/Alert/ConfirmationDialog';
import ButtonAdd from '../../components/Button/ButtonAdd';
import RefreshButton from '../../components/Button/RefreshButton';
import { Link, useNavigate } from 'react-router-dom';
import SearchInput from '../../components/Search/SearchInput';

const Salas = () => {
  const [salas, setSalas] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const { getToken } = useContext(KeycloakConf);
  const [pageable, setPageable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');


  const navigate = useNavigate();
  const loadSalas = async () => {
    setLoading(true);
    const token = getToken();

    try {
      const salasData = await fetchSalas(token, page, rowsPerPage, '', searchQuery);
      console.log(salasData);

      setSalas(salasData.content);
      setPageable(salasData.page)
    } catch (error) {
      console.log(error);

      console.error('Error cargando salas:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSalas();
  }, [getToken, page, rowsPerPage]);

  const handleDelete = (id) => {
    setDialogMessage(`El registro seleccionado será permanentemente eliminado del sistema.`);
    setItemToDelete(id);
    setDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    const token = getToken();
    try {
      await deleteSalas(token, itemToDelete);
      setSalas(salas.filter(sala => sala.id !== itemToDelete));
      setToastMessage('Registro eliminado con éxito');
      setToastType('success');
    } catch (error) {
      console.error('Error eliminando registro:', error);
      setToastMessage('El registro no fue eliminado');
      setToastType('error');
    }
    setToastOpen(true);
    setDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseToast = () => {
    setToastOpen(false);
  };

  const handleRefresh = (item) => {
    setSearchQuery("");
    loadSalas();
  };

  const handleEditSalas = (sala) => {
    navigate('/api/core/salas/editar_salas', { state: { sala } });
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      loadSalas();
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  }

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Link to="/api/core/salas/crear_salas">
            <ButtonAdd ariaLabel="añadir nueva sala" />
          </Link>
          &nbsp;
          <RefreshButton onClick={handleRefresh} />
        </Box>

        <Box flexBasis="300px" ml={2}>
          <SearchInput
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Buscar Sala..."
            ariaLabel="Campo de busqueda para salas"
            tooltipTitle="Escriba para buscar"
            onKeyDown={handleSearchKeyDown}
          />
        </Box>
      </Box>

      <Box>
        {loading ? <p>Cargando ...</p> : (
          <SalaTable
            salas={salas}
            onDelete={handleDelete}
            onEdit={handleEditSalas}
            pageable={pageable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </Box>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={dialogMessage}
      />

      <ToastAlertDelete
        open={toastOpen}
        onClose={handleCloseToast}
        message={toastMessage}
        type={toastType}
      />
    </>
  );
};

export default Salas;