import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FormOficialia from './Formulario/FormOficialia';
import KeycloakConf from '../../../api/keycloakConf';

const EditOficialia = () => {
    const location = useLocation();
    const { oficialia } = location.state || {};
    const { getToken } = useContext(KeycloakConf);

    const navigate = useNavigate();

    return (
        <div>
            {oficialia ? (
                <FormOficialia
                    token={getToken()}
                    onAddOficialia={async () => {
                        navigate('/api/core/oficialias');
                    }}
                    oficialiaToEdit={oficialia}
                />
            ) : (
                <p>No se encontró la oficialia.</p>
            )}
        </div>
    );
};

export default EditOficialia;