import Swal from "sweetalert2";
import "../custom-swal.css";

const SweetAlert = {
  confirmDeletion: async (message) => {
    const result = await Swal.fire({
      title: `¿Eliminar el registro?`,
      text: "El registro seleccionado será permanentemente eliminado del sistema.",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "No, cancelar",
      customClass: {
        confirmButton: "custom-swal-button mui-button mui-button--success", // Clase personalizada
        cancelButton: "custom-swal-button mui-button mui-button--error",   // Clase personalizada
      },
      buttonsStyling: false,
    });

    return result.isConfirmed;
  },

  showSuccess: (message) => {
    Swal.fire({
      title: "¡Excelente!",
      text: `Registro eliminado con éxito`,
      confirmButtonText: "Aceptar",
      customClass: {
        confirmButton: "custom-swal-button mui-button mui-button--success", // Clase personalizada
      },
      buttonsStyling: false,
    });
  },

  showError: (message) => {
    Swal.fire({
      title: "Error",
      text: `El registro de ${message} no fue eliminado`,
      icon: "error",
      confirmButtonText: "Aceptar",
      customClass: {
        confirmButton: "custom-swal-button mui-button mui-button--error", // Clase personalizada
      },
      buttonsStyling: false,
    });
  }
};

export default SweetAlert;
