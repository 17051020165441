import React from 'react';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const OficialiasTable = ({
  oficialias,
  onDelete,
  onEdit,
  pageable,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className='table-header'>Nombre</TableCell>
                <TableCell className='table-header'>Materia</TableCell>
                <TableCell className='table-header'>Tipo</TableCell>
                <TableCell className='table-header'>Juzgado</TableCell>
                <TableCell className='table-header'>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {oficialias
                .map((oficialia) => (
                  <StyledTableRow
                    key={oficialia.id}
                    onClick={() => onEdit(oficialia)} // Acción al hacer click en la fila
                    hover // Agrega un efecto de hover
                    style={{ cursor: 'pointer' }} // Cambio del cursor al pasar por la fila
                  >
                    <TableCell>{oficialia.nombre}</TableCell>
                    <TableCell>{oficialia.materiaNombresList}</TableCell>
                    <TableCell>{oficialia.tipoOficialiaNombre}</TableCell>
                    <TableCell>{oficialia.juzgadoNombre}</TableCell>
                    <TableCell>
                      <Tooltip title="eliminar">
                        <DeleteIcon className='icon-color' onClick={(event) => {
                          event.stopPropagation();
                          onDelete(oficialia);
                        }}>
                        </DeleteIcon>
                      </Tooltip>
                    </TableCell>
                  </StyledTableRow>
                ))}
              {oficialias.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No hay oficialias disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default OficialiasTable;
