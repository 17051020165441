import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Stack,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';

const ListaValorTable = ({ 
  list, 
  onDelete, 
  onEdit, 
  pageable, 
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell align="left">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list
                .map((item) => (
                  <TableRow 
                    key={item.id}
                    onClick={() => onEdit(item)}
                    hover
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>{item.nombre}</TableCell>
                    <TableCell>{item.estado}</TableCell>
                    <TableCell align="left">
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={(event) => {
                            event.stopPropagation();
                            onDelete(item.id, item.nombre);
                          }}
                        >
                          Borrar
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              {list.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No hay registros disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationGeneric
          pageable = {pageable}
          page = {page}
          setPage = {setPage}
          rowsPerPage = {rowsPerPage}
          setRowsPerPage = {setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default ListaValorTable;
