import axios from 'axios';

const urlJuicios = process.env.REACT_APP_TRIALS_API_URL + `/api/core/tipojuicio`;
const urlJuiciosDemandas = process.env.REACT_APP_TRIALS_API_URL + `/api/core/tipojuicio/oralidad`;

export const getAllTipoJuicio = async (token) => {
    try {
      const response = await axios.get( urlJuicios, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error al obtener las juicios', error);
      throw error;
    }
  }

  export const getAllTipoJuicioDemandas = async (token) => {
    try {
      const response = await axios.get( urlJuiciosDemandas, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response);
      
      return response.data;
    } catch (error) {
      console.error('Error al obtener las juicios', error);
      throw error;
    }
  }