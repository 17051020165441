import React from 'react';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SalasTable = ({ 
  salas, 
  onEdit,
  pageable, 
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
   }) => {

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className='table-header'>Sala</TableCell>
                <TableCell className='table-header'>Juez</TableCell>
                <TableCell className='table-header'>Juzgado</TableCell>
                <TableCell className='table-header'>Horario</TableCell>
                <TableCell className='table-header'>Estatus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {salas
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((sala) => (
                  <StyledTableRow
                    key={sala.id}
                    onClick={() => onEdit(sala)}
                    hover
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>{sala.nombre}</TableCell>
                    <TableCell>{sala.juez}</TableCell>
                    <TableCell>{sala.juzgado}</TableCell>
                    <TableCell>{sala.bloque.horaInicial + ' - ' + sala.bloque.horaFinal}</TableCell>
                    <TableCell>
                      {sala.estado == "ACTIVE" ? <span>Activo</span> : <span>Inactivo</span>}
                    </TableCell>
                   
                  </StyledTableRow>
                ))}
              {salas.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No hay salas disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationGeneric
          pageable = {pageable}
          page = {page}
          setPage = {setPage}
          rowsPerPage = {rowsPerPage}
          setRowsPerPage = {setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default SalasTable;