import React, { useState, useEffect, useContext } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Button,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SearchInput from "../../../components/Search/SearchInput";
import keycloakConf from "../../../api/keycloakConf";
import { fetchEncargadosCarrito } from "../../../shared/services/PersonasService";

const HeaderBandejaSalida = ({
  errorMessages,
  loadBandejas,
  folioEscaneado,
  searchQuery,
  setSearchQuery,
  encargadosCarrito,
  setEncargadosCarrito,
  setDrawerOpen,
  setDialogOpen
}) => {
  const navigate = useNavigate();
  const { getToken } = useContext(keycloakConf);

  const [formData, setFormData] = useState({
    expediente: "",
    año: "",
    respEntrega: null,
  });

  useEffect(() => {
    loadEncargadosCarrito();
  }, [getToken]);

  const loadEncargadosCarrito = async () => {
    try {
      const token = getToken();
      const encargados = await fetchEncargadosCarrito(token);
      setEncargadosCarrito(encargados || []);
    } catch (error) {
      console.log("Error al obtener los Encargados Carrito");
    }
  };

  const handleSubmitSalida = async (e) => {
    setDialogOpen(true);
  };

  const handleCancel = () => {
    navigate("/");
  };

  const handleVerCarrito = () => {setDrawerOpen(true);};

  const handleResponsableChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      respEntrega: { id: event.target.value },
    }));
  };

  return (
    <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={4}>
          <Button
            type="button"
            onClick={handleSubmitSalida}
            variant="contained"
            color="success"
          >
            Salida
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleCancel}
            style={{ marginLeft: "10px" }}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            onClick={handleVerCarrito}
            style={{ marginLeft: "10px" }}
          >
            <AddShoppingCartIcon sx={{ mr: 1 }} /> Ver Carrito
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={3} sx={{ paddingRight: { md: 4, xs: 0 } }}>
          <FormControl
            fullWidth
            variant="outlined"
            error={!!errorMessages.encargadoCarrito}
          >
            <InputLabel id="select-responsable-entrega-label">Responsable de la entrega</InputLabel>
            <Select
              labelId="select-responsable-entrega-label"
              value={formData?.respEntrega?.id || ""}
              onChange={handleResponsableChange}
              label="Responsable_Entrega"
              required
            >
              {encargadosCarrito.map((encargadoCarrito) => (
                <MenuItem key={encargadoCarrito.id} value={encargadoCarrito.id}>
                  {encargadoCarrito.nombreCompleto}
                </MenuItem>
              ))}
            </Select>
            {errorMessages.encargadoCarrito && (
              <FormHelperText>{errorMessages.encargadoCarrito}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <Grid container sx={{ marginLeft: "2px" }} alignItems="center">
            <Grid item>Folio escaneado:</Grid>
            <Grid item sx={{ ml: 3 }} >
              {folioEscaneado}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Buscar "
            ariaLabel="Campo de busqueda para bandeja de entrada"
            tooltipTitle="Escriba para buscar"
            onKeyDown={(e) => e.key === "Enter" && loadBandejas()}
          />
        </Grid>
      </Grid>
  );
};

export default HeaderBandejaSalida;
