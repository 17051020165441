export const validateFields = (data, rules, showAlertValidate) => {
  let errors = {};

  for (const field in rules) {
    const fieldParts = field.split('.');
    let value = data;

    for (const part of fieldParts) {
      if (value) {
        value = value[part];
      }
    }
    if (rules[field].required && !value) {
      errors[field] = 'Este campo es obligatorio';
      showAlertValidate('Complete la informacion faltante', 'error', 'Informacion incompleta');
    }


    if (rules[field].validate) {

      const isValid = rules[field].validate(value);
      if (value && !isValid) { 
        errors[field] = 'El valor del campo no es válido';
        showAlertValidate('Formato incorrecto en algún campo', 'error', 'Error de formato');
      }
    }

    
  }

  return errors;
};