import axios from 'axios';

export const fetchList = async (token, page = 0, size = 10, sort = '', key = '') => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/recepcion`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        sort,
        key
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al listar bandeja recepcion:', error);
    throw error;
  }
};