import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Chip,
  Box,
  Grid,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Divider,
  Switch,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { addOficialia, updateOficialia, fetchOficialiasById } from '../../OficialiasService';
import { fetchSedesDomicilio } from '../../../Sedes/SedeService';
import { validateFields } from '../../../../components/Alert/ValidationRequired/validationRequired';
import { fetchJuzgadoById } from '../../../../shared/services/JuzgadosService';
import { fetchTipoOficialia } from '../../../../shared/services/TipoOficialiaService';
import { fetchMaterias } from '../../../Materias/MateriasService';
import { fetchJuzgados } from '../../../../shared/services/JuzgadosService';
import { useNavigate } from 'react-router-dom';
import { useToastAlert } from '../../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import { useToastAlertValidation } from '../../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchJuzgadoByOficialiaId } from '../../../../shared/services/JuzgadosService';

const FormOficialia = ({ token, onAddOficialia, oficialiaToEdit }) => {
  const navigate = useNavigate();
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();

  const [formData, setFormData] = useState({
    id: '',
    nombre: '',
    responsable: '',
    estado: 'ACTIVE',
    juzgadoId: '',
    materiaNombres: [],
    materiaId:[],
    sede: {
      id: ''
    },
    tipo: {
      id: '',
      nombre: '' 
    },
  });

  const [materias, setMaterias] = useState([]);
  const [juzgados, setJuzgados] = useState([]);
  const [materiaId, setMateriaId] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [sedes, setSedes] = useState([]);
  const [tipos, setTipoOficialias] = useState([]);
  const [tipoOfi, setTipoNombre] = useState("");  
  
  
  const [materiaToAdd, setMateriaToAdd] = useState('');
  const [singleSelect, setSingleSelect] = useState(false); 

 
  const [juzgadoToAdd, setJuzgadoToAdd] = useState('');
  const [juzgadosSeleccionados, setJuzgadosSeleccionados] = useState([]);  


  
  useEffect(() => {
    loadMaterias();
    loadJuzgados();
    loadSedesAndDomicilios();
    loadTipoOficialia();
  
    if (oficialiaToEdit) {
      getOficialiaById();
      getJuzgadoByOficialiaId();

      if (oficialiaToEdit.tipo && oficialiaToEdit.tipo.nombre === "Mayor") {
        setFormData(prevData => ({
          ...prevData,
          materiaNombres: oficialiaToEdit.materiaNombres ? [oficialiaToEdit.materiaNombres[0]] : [],
          materiaId: oficialiaToEdit.materiaId ? [oficialiaToEdit.materiaId[0]] : [],
        }));
        setJuzgadosSeleccionados(prevJuzgados => 
          prevJuzgados.length > 0 ? [prevJuzgados[0]] : []
        );
      } else {
        
        setFormData(prevData => ({
          ...prevData,
          ...oficialiaToEdit,
          sede: oficialiaToEdit.sede || { id: oficialiaToEdit.sedeId },
          tipo: oficialiaToEdit.tipo || { id: '', nombre: '' },
          materiaNombres: oficialiaToEdit.materiaNombres || [],
          materiaId: oficialiaToEdit.materiaId || [],
          juzgadoId: oficialiaToEdit.juzgadoId || ''
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        estado: 'ACTIVE',
      }));
    }
  }, [token, oficialiaToEdit]);
  
  

  const loadTipoOficialia = async () => {
    try {
      const tipoOficialiaData = await fetchTipoOficialia(token);
      if (Array.isArray(tipoOficialiaData)) {
        setTipoOficialias(tipoOficialiaData);
      } else {
        console.error('La respuesta no es un array:', tipoOficialiaData);
      }
    } catch (error) {
      console.error('Error al obtener los tipos de oficialia:', error);
    }
  };

  const loadMaterias = async () => {
    try {
      const materias = await fetchMaterias(token);
      setMaterias(materias);
    } catch (error) {
      console.error('Error al obtener materias:', error);
    }
  };

  const loadJuzgados = async () => {
    try {
      const juzgadosData = await fetchJuzgados(token, 0, 20); 
      setJuzgados(juzgadosData.content || []); 
    } catch (error) {
      console.error('Error al obtener los Juzgados:', error);
    }
  };
  

  const loadSedesAndDomicilios = async () => {
    try {
      const response = await fetchSedesDomicilio(token);
      if (response && Array.isArray(response.content)) {
        setSedes(response.content);
      } else {
        console.error('La respuesta no contiene un array en `content`:', response);
      }
    } catch (error) {
      console.error('Error al obtener las sedes:', error);
    } 
  };


  const handleAddMateria = (selectedValue) => {
    const isMayor = formData.tipo.nombre === "Mayor";
    
    if (isMayor) {
      setFormData(prevFormData => ({
        ...prevFormData,
        materiaNombres: [selectedValue.nombre],
        materiaId: [selectedValue.id],
      }));
      setMateriaId([selectedValue.id]);
    } else {
      if (selectedValue.id) {
        const isMateriaExist = formData.materiaNombres.includes(selectedValue.nombre);

        if (!isMateriaExist) {
          setFormData(prevFormData => ({
            ...prevFormData,
            materiaNombres: [
              ...prevFormData.materiaNombres,
              selectedValue.nombre
            ],
            materiaId: [
              ...prevFormData.materiaId,
              selectedValue.id 
            ]
          }));
          setMateriaId(prevMateriaId => [...prevMateriaId, selectedValue.id]);
        }
      }
    }

    setMateriaToAdd('');
  };
  
  const handleDeleteMateria = (materiaToDelete) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      materiaNombres: prevFormData.materiaNombres.filter(materia => materia !== materiaToDelete),
      materiaId: prevFormData.materiaId.filter((id, index) => prevFormData.materiaNombres[index] !== materiaToDelete)
    }));
  };
  

  const getOficialiaById = function () {
    const loadbyId = async () => {
      try {
        const oficialia = await fetchOficialiasById(token, oficialiaToEdit.id);
        setFormData(prevFormData => ({
          ...prevFormData,
          id: oficialia.id,
          nombre: oficialia.nombre,
          tipo: oficialia.tipo
        }));
        
        getJuzgadoByOficialiaId();

      } catch (error) {
        console.error('Error al obtener oficialia:', error);
      }
    }
    loadbyId();

  }

  
  const getJuzgadoByOficialiaId = function () {
    const loadJuzgados = async () => {
      try {
        const juzgados = await fetchJuzgadoByOficialiaId(token, oficialiaToEdit.id);
        if (juzgados && juzgados.length > 0) {
          setJuzgadosSeleccionados(juzgados);
          setFormData(prevFormData => ({
            ...prevFormData,
            juzgadoId: juzgados.map(juzgado => juzgado.id)
          }));
        }
      } catch (error) {
        console.error('Error al obtener los juzgados:', error);
      }
    }
    loadJuzgados();
  }

  

  const clearMaterias = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      materiaNombres: [], 
      materiaId: [] 
    }));
  };

  const resetForm = () => {
    setMateriaId([]);
    setFormData({
      id: '',
      nombre: '',
      responsable: '',
      estado: 'ACTIVE',
      juzgadoId: '',
      materiaNombres: [],
      materiaId: '',
      sede: {
        id: ''
      },
      tipo: {
        id: '',
        nombre: ''
      },
    });
    setErrorMessages({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`handleChange: ${name} = ${value}`);
    
    if (name === 'tipo.id') {
      const selectedTipoOficialia = tipos.find(tipo => tipo.id === value);
      const isMayor = selectedTipoOficialia && selectedTipoOficialia.nombre === 'Mayor';
      setTipoNombre(selectedTipoOficialia ? selectedTipoOficialia.nombre : "");
      setSingleSelect(isMayor);
      setFormData(prevData => ({
        ...prevData,
        tipo: { 
          id: value,
          nombre: selectedTipoOficialia ? selectedTipoOficialia.nombre : ""
        }
      }));
      if(isMayor){
        clearMaterias();
        clearJuzgados();
      } 
    } else if (name.startsWith('sede.')) {
      const fieldName = name.split('.')[1];
      setFormData(prevData => ({
        ...prevData,
        sede: { ...prevData.sede, [fieldName]: value }
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }

    setErrorMessages(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const rules = {
      nombre: { required: true },
      estado: { required: true },
      "tipo.id" : { required: true },
      "sede.id": { required: true }
    };

    const errors = validateFields(formData, rules, showAlertValidate);
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }

    if (!validateJuzgados()) {
      return; 
    }

    const uniqueMaterias = [...new Set(formData.materiaId)];
    const ids = materias
      .filter(obj => uniqueMaterias.includes(obj.id))
      .map(obj => ({ id: obj.id}));
    let formDataToSubmit = {
      id: formData.id,
      estado: formData.estado,
      tipoOficialia: { id: formData.tipo.id },
      nombre: formData.nombre,
      responsable: formData.responsable,
      sede: { id: formData.sede.id },
      juzgado: { id: formData.juzgadoId },
      audit: {},
      "materias": ids,
      juzgados: juzgadosSeleccionados.map(juzgado => ({ id: juzgado.id }))
    };
    try {
      if (formData.id) {
        await updateOficialia(token, formDataToSubmit);
        showUpdateSnackbar('Registro actualizado con éxito', 'success');
      } else {
        await addOficialia(token, formDataToSubmit);
        showSnackbar('Registro guardado con éxito', 'success');
      }

      if (onAddOficialia) {
        onAddOficialia();
      }

      resetForm();
      clearJuzgados();
    } catch (error) {
      console.error('Error al guardar oficialia:', error);
      showSnackbar('¡Algo salió mal!', 'error');
    }
  };

  const handleCancel = () => {
    navigate('/api/core/oficialias');
  };

  const handleAddJuzgado = (selectedJuzgado) => {
    const isMayor = formData.tipo.nombre === "Mayor";

    if (isMayor) {
      setJuzgadosSeleccionados([selectedJuzgado]);
    } else {
      const isJuzgadoExist = juzgadosSeleccionados.some((j) => j.id === selectedJuzgado.id);
      
      if (!isJuzgadoExist) {
        setJuzgadosSeleccionados((prevJuzgados) => [...prevJuzgados, selectedJuzgado]);
      }
    }

    setJuzgadoToAdd('');
};
  
  const validateJuzgados = () => {
    const isMayor = formData.tipo.nombre === "Mayor";
    
    if (isMayor) {
      if (juzgadosSeleccionados.length !== 1) {
        showSnackbar('Las oficialías de tipo Mayor deben tener exactamente un juzgado.', 'error');
        return false;
      }
    } else {
      if (juzgadosSeleccionados.length < 2) {
        showSnackbar('Las oficialías de tipo Común deben tener al menos dos juzgados.', 'error');
        return false;
      }
    }
    return true;  
  };

  // eliminar juzgado... 
  const handleDeleteJuzgado = (juzgadoToDelete) => {
    setJuzgadosSeleccionados((prevJuzgados) =>
      prevJuzgados.filter((juzgado) => juzgado.id !== juzgadoToDelete.id)
    );
  };

  const clearJuzgados = () => {
    setJuzgadosSeleccionados([]);  
    setFormData(prevFormData => ({
      ...prevFormData,
      juzgadoId: '', 
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Button type="button" onClick={handleSubmit} variant="contained" className="button-save">
            Guardar
          </Button>
          <Button
            variant="outlined"
            className="button-close"
            onClick={handleCancel}
            style={{ marginLeft: '10px', backgroundColor: 'red', color: 'white' }}
          >
            Cerrar
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        <Divider />
      </Box>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="nombre"
            label="Nombre"
            variant="outlined"
            fullWidth
            value={formData.nombre}
            onChange={handleChange}
            error={!!errorMessages.nombre}
          />
          {errorMessages.nombre && <FormHelperText error>{errorMessages.nombre}</FormHelperText>}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="outlined" error={!!errorMessages['tipo.id']}>
            <InputLabel id="tipo-label">Tipo Oficialia</InputLabel>
            <Select
              labelId="tipo-label"
              name="tipo.id"
              value={formData.tipo.id}
              onChange={handleChange}
              label="Tipo Oficialia"
              error={!!errorMessages['tipo.id']}
            >
              {tipos.map((tipo) => (
                <MenuItem key={tipo.id} value={tipo.id}>{tipo.nombre}</MenuItem>
              ))}
            </Select>
            {errorMessages['tipo.id'] && <FormHelperText>{errorMessages['tipo.id']}</FormHelperText>}
          </FormControl>
        </Grid>
        
        <Grid item xs={12} sm={12} md={8}>
          <FormControl fullWidth variant="outlined" error={!!errorMessages['sede.id']}>
            <InputLabel id="sede-label">Domicilios</InputLabel>
            <Select
              labelId="sede-label"
              name="sede.id"
              value={formData.sede.id}
              onChange={handleChange}
              label="Domicilios"
              error={!!errorMessages['sede.id']}
            >
              {sedes.map((sede) => (
                <MenuItem key={sede.sedeId} value={sede.sedeId}>{sede.sedeNombre}, C.{sede.calle}, No.{sede.exterior}, Col:{sede.colonia}, {sede.estadoRepublica}, CP:{sede.codigoPostal}</MenuItem>
              ))}
            </Select>
            {errorMessages['sede.sedeId'] && <FormHelperText>{errorMessages['sede.sedeId']}</FormHelperText>}
          </FormControl>

            <h3>Agregar Materia</h3>
            <Select
              value={materiaToAdd.id || ''}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setMateriaToAdd(selectedValue.id);
                handleAddMateria(selectedValue);
              }}
              displayEmpty
              renderValue={materiaToAdd !== '' ? undefined : () => 'Seleccionar Materia'}
              labelId="materia-label1"
              name="materiaId"
            >
              {materias.map((materia) => (
                <MenuItem key={materia.id} value={materia}>{materia.nombre}</MenuItem>
              ))}
            </Select>

            <h3>Materias seleccionadas</h3>
            {formData.materiaNombres.map((materia, index) => (
              <Chip
                key={index}
                label={materia}
                onDelete={() => handleDeleteMateria(materia)}
                style={{ marginRight: 5, marginBottom: 5 }}
              />
            ))}

            <h3>Agregar Juzgado</h3>
            <Select
              labelId="juzgado-label"
              value={juzgadoToAdd || ''}
              onChange={(e) => handleAddJuzgado(e.target.value)}
              displayEmpty
              renderValue={juzgadoToAdd !== '' ? undefined : () => 'Seleccionar Juzgado'}
            >
              {juzgados.map((juzgado) => (
                <MenuItem key={juzgado.id} value={juzgado}>
                  {juzgado.nombre}
                </MenuItem>
              ))}
            </Select>

            <h3>Juzgados seleccionados</h3>
            {juzgadosSeleccionados.map((juzgado, index) => (
              <Chip
                key={index}
                label={juzgado.nombre}
                onDelete={() => handleDeleteJuzgado(juzgado)}
                style={{ marginRight: 5, marginBottom: 5 }}
              />
            ))}
            {errorMessages['sede.sedeId'] && <FormHelperText>{errorMessages['sede.sedeId']}</FormHelperText>}
          </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.estado === "ACTIVE"}
                  onChange={(event) => handleChange({
                    target: {
                      name: 'estado',
                      value: event.target.checked ? 'ACTIVE' : 'INACTIVE'
                    }
                  })}
                  color="primary"
                />
              }
              label="Activar Oficialia"
            />
            {errorMessages.estado && <FormHelperText>{errorMessages.estado}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormOficialia;