import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ActorDemandadoForm from "./ActorDemandadoForm";
import keycloakConf from "../../../../api/keycloakConf";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateFields } from '../../../../components/Alert/ValidationRequired/validationRequired';
import { useToastAlert } from '../../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertValidation } from '../../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { validateCurp } from "../../../../components/CURP/validationCurp";
import { isValidEmail, onlyAlphaNumeric, onlyNames, onlyNumbers } from "../../../../components/Caracteres/caracteresEspeciales";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";
import { addDocumento } from "../../DemandaService";
import { getAllTipoJuicioDemandas } from "../../../TipoJuicio/TipoJuicioService";
import { getSello } from "../../DemandaService";

export default function FormDemandaFamiliar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [tabvalue, setTabValue] = useState(0);
  const [domicilioGeneralSeleccionado, setGeneralDomicilioSeleccionado] = useState('');
  const { getToken } = useContext(keycloakConf);
  const [juzgados, setJuzgados] = useState([]);
  const [juzgado, setjuzgado] = useState("");
  const { juicioId, nombreJuicio, materia } = location.state || {};
  const [errors, setErrors] = useState({});
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const [fechaHoraActual, setFechaHoraActual] = useState({ fecha: '', hora: '' });

  useEffect(() => {
    loadJuzgados();

    const interval = setInterval(() => {
      const now = new Date();
      setFechaHoraActual({ fecha: now.toLocaleDateString(), hora: now.toLocaleTimeString() });
    }, 1000);
    
    return () => clearInterval(interval);
  }, []);

  const loadJuzgados = async () => {
    try {
      const tiposJuiciosTemp = await getAllTipoJuicioDemandas(getToken());
      const tiposJuicios = tiposJuiciosTemp.filter(tipoJuicio => tipoJuicio.id !== juicioId);

      setJuzgados(tiposJuicios || []);
    } catch (error) {
      console.error("Error al obtener los Juzgados", error);
    }
  };
  const fieldsMap = {
    'actor.nombre': onlyNames,
    'actor.apellidoPaterno': onlyNames,
    'actor.apellidoMaterno': onlyNames,
    'actor.curp': onlyAlphaNumeric,
    'actpr.ine': onlyAlphaNumeric,
    'actor.celular': onlyNumbers,
    'demandado.nombre': onlyNames,
    'demandado.apellidoPaterno': onlyNames,
    'demandado.apellidoMaterno': onlyNames,
    'demandado.curp': onlyAlphaNumeric,
    'demandado.ine': onlyAlphaNumeric,
    'demandado.celular': onlyNumbers,
    'general.nombreAbogado': onlyNames,
    'general.cedulaAbogado': onlyAlphaNumeric,

  };

  const [nuevoDocumento, setNuevoDocumento] = useState({
    actor: {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      curp: "",
      ine: "",
      celular: "",
      domicilio: "",
      correoElectronico: "",
      tipoParte: 1
    },
    demandado: {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      curp: "",
      ine: "",
      domicilio: "",
      tipoParte: 2,
    },
    general: {
      domicilio: "",
      tieneAbogado: 1,
      nombreAbogado: "",
      cedulaAbogado: "",
      correoAbogado: "",
      tiposJuicios: [],
    },
    anexos: [],
    tipoJuicioId: juicioId
  });


  const validationRules = {
    actor: { // Tab "Actor"
      'actor.nombre': { required: true },
      'actor.apellidoPaterno': { required: true },
      'actor.curp': { validate: validateCurp },
      'actor.celular': { required: true },
      'actor.domicilio': { required: true },
      'actor.correoElectronico': { required: true, validate: isValidEmail }
    },
    demandado: { // Tab "Demandado"
      'demandado.nombre': { required: true },
      'demandado.apellidoPaterno': { required: true },
      'demandado.curp': { validate: validateCurp }
    },
    general: { // Tab "General"
      'general.domicilio': { required: true },
      'general.cedulaAbogado': { required: nuevoDocumento.general.tieneAbogado },
      'general.correoAbogado': { required: nuevoDocumento.general.tieneAbogado, validate: isValidEmail }
    }
  };

  const validacionCampos = async () => {


    // Obtener las reglas de validación para todas las secciones
    const allValidationRules = {
      ...validationRules.actor,
      ...validationRules.demandado,
      ...validationRules.general
    };

    // Validar todos los campos con las reglas combinadas
    const validationErrors = validateFields(nuevoDocumento, allValidationRules, showAlertValidate);

    // Si hay errores de validación, actualiza el estado de errores y no envía el formulario
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false;
    }

    return true;
  }


  const handleButtonSave = async () => {
    const token = await getToken();

    try {
      let documento = await addDocumento(token, nuevoDocumento);

      showSnackbar('Demanda generada con exito', 'success');

      const sello = await getSello(token, documento.id);
      var blob = new Blob([sello], { type: 'application/pdf' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = 'sello.pdf';
      link.click(); 
      navigate('/api/workflow/demanda');
    } catch (error) {
      console.error('Error al agregar el documento:', error);
      showSnackbar('¡Algo salió mal!', 'error');
    }

    setModalOpen(false);

  }

  const handleClose = () => {
    navigate('/api/workflow/demanda');
  }



  const handleTabChange = (event, newValue) => {

    setTabValue(newValue);
  };

  const handleConfirmSave = async (e) => {
    e.preventDefault();
    const validacion = await validacionCampos();
    if (validacion) {
      setModalOpen(true);
    }

  }

  const formatPhoneCelNumbers = (value) => {
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length === 0) {
      return '';
    } else if (numericValue.length <= 3) {
      return `(${numericValue}`;
    } else if (numericValue.length <= 6) {
      return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
    } else {
      return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)} ${numericValue.slice(6, 10)}`;
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const keys = name.split('.');

    setNuevoDocumento(prevState => {
      let updatedState = { ...prevState };

      let current = updatedState;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }

      // Validación especial para el campo 'telefono'
      let formattedValue = value;
      if (name === 'actor.celular' ) {

        
        // Si el campo es 'telefono', se formatea el valor
        formattedValue = formatPhoneCelNumbers(value);
        const numericValue = value.replace(/\D/g, '');
        current[keys[keys.length - 1]] = numericValue; // Guardamos el valor numérico

    
        event.target.value = formattedValue; // Mostramos el valor formateado en el input
      
      } else {
        // Si no es 'telefono', simplemente guardamos el valor como está
        const caracterValue = (fieldsMap[name] || ((val) => val))(value);
        current[keys[keys.length - 1]] = caracterValue;
      }

      const updatedErrors = validateFields(updatedState, validationRules, showAlertValidate);

      setErrors(updatedErrors);

      return updatedState;
    });
  };

  const handleChangeDomicilioGeneral = (e) => {
    let value = e.target.value;

    setGeneralDomicilioSeleccionado(value);

    if (value === '1') {

      setNuevoDocumento(prevState => ({
        ...prevState,
        general: {
          ...prevState.general,
          domicilio: prevState.actor.domicilio
        }
      }));
    } else if (value === '2') {

      setNuevoDocumento(prevState => ({
        ...prevState,
        general: {
          ...prevState.general,
          domicilio: prevState.demandado.domicilio
        }
      }));
    } else if (value === '3') {

      setNuevoDocumento(prevState => ({
        ...prevState,
        general: {
          ...prevState.general,
          domicilio: ""
        }
      }));
    }

  }

  const handleJuzgadoChange = (e) => {
    let tiposJuiciosSelected = e.target.value;



    if (tiposJuiciosSelected) {

      let tipoJuicioObject = juzgados.find(element => element.id === tiposJuiciosSelected);

      
      let contieneUnilateral = false;
      let contieneBilateral = false;

      nuevoDocumento.general.tiposJuicios.forEach(element => {
        if (element.nombre === "Familiar Oralidad (Divorcio Incausado Unilateral)") {
          contieneUnilateral = true;
        }

        if (element.nombre === "Familiar Oralidad (Divorcio Incausado Bilateral)") {
          contieneBilateral = true;
        }
      });

      if ((contieneUnilateral && tipoJuicioObject.nombre === "Familiar Oralidad (Divorcio Incausado Bilateral)")
        || (contieneBilateral && tipoJuicioObject.nombre === "Familiar Oralidad (Divorcio Incausado Unilateral)")) {
        showSnackbar('No puede agregar dos tipos de juicios de divorcio Incausado', 'error');
        return;
      }


      setjuzgado(tiposJuiciosSelected);

      let existeJuzgado = nuevoDocumento.general.tiposJuicios.find(element => element.id === tiposJuiciosSelected);


      if (!existeJuzgado) {
        let juiciosTemp = nuevoDocumento.general.tiposJuicios;

        juiciosTemp.push(juzgados.filter(element => element.id === tiposJuiciosSelected)[0]);

        setNuevoDocumento(prevState => ({
          ...prevState,
          general: {
            ...prevState.general,
            tiposJuicios: juiciosTemp
          }
        }));
      }
    }

  }


  const handleDeleteTiposJuiciosGenerales = (juzgado) => {
    let tiposJuiciosSelected = juzgado;
    let juiciosTemp = nuevoDocumento.general.tiposJuicios.filter(element => element.id != tiposJuiciosSelected.id)

    setNuevoDocumento(prevState => ({
      ...prevState,
      general: {
        ...prevState.general,
        tiposJuicios: juiciosTemp
      }
    }));
  }

  const handleAddAnexo = () => {

    let anexoTemp = nuevoDocumento.anexos;
    anexoTemp.push('');

    setNuevoDocumento(prevState => ({
      ...prevState,
      anexos: anexoTemp
    }));
  }

  const handleAnexoChange = (index, value) => {
    const newValue = value
    const newAnexo = nuevoDocumento.anexos;

    newAnexo[index] = newValue;

    setNuevoDocumento(prevState => ({
      ...prevState,
      anexos: newAnexo
    }));

  }

  const handleRemoveAnexo = (index) => {
    const newAnexos = nuevoDocumento.anexos.filter((_, i) => i !== index);
    setNuevoDocumento(prevState => ({
      ...prevState,
      general: {
        ...prevState.general,
        anexos: newAnexos
      }
    }));
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        {/* Sección de botones */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button type="submit" variant="contained" className="button-save" onClick={handleConfirmSave}>
            Guardar
          </Button>
          <Button variant="contained" className="button-close" onClick={handleClose} sx={{ ml: 2 }}>
            Cerrar
          </Button>
        </Box>

        {/* Sección de fecha y hora en una sola línea */}
        <Typography variant="body1">
          Fecha: {fechaHoraActual.fecha} | Hora: {fechaHoraActual.hora}
        </Typography>
      </Box>

      <ConfirmationModal
        // open={modalOpen}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleButtonSave}
        title="¿Generar demanda?"
        message="Se generará una nueva demanda en el sistema."
        confirmationButton="Si, generar"
        closeButton="No, cancelar"
      />

      <Paper elevation={9} sx={{ padding: 4, flex: 1, overflowY: 'auto' }}>
        <form>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Tabs value={tabvalue} onChange={handleTabChange} variant="fullWidth">
                <Tab label="Actor" />
                <Tab label="Demandado" />
                <Tab label="General" />
              </Tabs>
            </Grid>

            {tabvalue === 0 && (
              <>
                <ActorDemandadoForm
                  data={nuevoDocumento.actor}
                  handleInputChange={handleInputChange}
                  prefix="actor"
                  errors={errors}
                  formatPhoneCelNumbers={formatPhoneCelNumbers}
                />

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={nuevoDocumento.general.tieneAbogado === 1}
                        onChange={(e) => setNuevoDocumento(prevState => ({
                          ...prevState,
                          general: {
                            ...prevState.general,
                            tieneAbogado: e.target.checked ? 1 : 0
                          }
                        }))}
                        color="primary"
                      />
                    }
                    label="Tiene abogado"
                  />

                </Grid>

                {nuevoDocumento.general.tieneAbogado === 1 && (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        label="Nombre"
                        name="general.nombreAbogado"
                        disabled={!nuevoDocumento.general.tieneAbogado}
                        fullWidth
                        value={nuevoDocumento.general.nombreAbogado}
                        onChange={handleInputChange}
                        error={!!errors["general.nombreAbogado"]}
                        helperText={errors["general.nombreAbogado"] || ''}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        label="Cédula"
                        name="general.cedulaAbogado"
                        disabled={!nuevoDocumento.general.tieneAbogado}
                        fullWidth
                        value={nuevoDocumento.general.cedulaAbogado}
                        onChange={handleInputChange}
                        error={!!errors["general.cedulaAbogado"]}
                        helperText={errors["general.cedulaAbogado"] || ''}

                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        label="Correo"
                        name="general.correoAbogado"
                        fullWidth
                        value={nuevoDocumento.general.correoAbogado}
                        disabled={!nuevoDocumento.general.tieneAbogado}
                        onChange={handleInputChange}
                        error={!!errors["general.correoAbogado"]}
                        helperText={errors["general.correoAbogado"] || ''}
                      />
                    </Grid>
                  </>
                )
                }

              </>)}

            {tabvalue === 1 && (

              <ActorDemandadoForm
                data={nuevoDocumento.demandado}
                handleInputChange={handleInputChange}
                prefix="demandado"
                errors={errors}
                formatPhoneCelNumbers={formatPhoneCelNumbers}
              />

            )}

            {tabvalue === 2 && (
              <>
                <Grid item xs={6}>

                  <Box paddingBottom={8}>
                    <Typography variant="h6">Juicios</Typography>
                    {nuevoDocumento.general.tiposJuicios.map((juzgado) => (
                      <Chip key={juzgado.id} id={juzgado.id} label={juzgado.nombre} onDelete={() => handleDeleteTiposJuiciosGenerales(juzgado)} />
                    ))}

                  </Box>
                  <Typography variant="h6">Agregar Juicio</Typography>

                  <Select
                    labelId="select-juzgado-label"
                    value={juzgado}
                    onChange={handleJuzgadoChange}
                    label="Juzgado"
                    fullWidth
                    required
                    displayEmpty
                    renderValue={juzgado !== '' ? undefined : () => 'Seleccionar tipo de juicio'}
                  >


                    {/* Opciones de juzgados */}
                    {juzgados.map((juzgado) => (
                      <MenuItem key={juzgado.id} value={juzgado.id}>
                        {juzgado.nombre}
                      </MenuItem>
                    ))}
                  </Select>

                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6">Último domicilio familiar</Typography>
                  <RadioGroup onChange={handleChangeDomicilioGeneral}  >

                    <FormControlLabel
                      key={1}
                      value="1"
                      control={<Radio />}
                      label={`Mismo que el domicilio del actor`}
                    />

                    <FormControlLabel
                      key={2}
                      value="2"
                      control={<Radio />}
                      label={`Mismo que el domicilio del demandado`}
                    />

                    <FormControlLabel
                      key={3}
                      value="3"
                      control={<Radio />}
                      label={`Otro domicilio`}
                    />

                  </RadioGroup>

                  <TextField
                    label="Domicilio"
                    name="general.domicilio"
                    onChange={handleInputChange}
                    fullWidth
                    value={nuevoDocumento.general.domicilio}
                    disabled={domicilioGeneralSeleccionado != 3}
                    error={!!errors["general.domicilio"]}
                    helperText={errors["general.domicilio"] || ''}
                  />

                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">Anexos</Typography>
                  {nuevoDocumento.anexos.map((anexo, index) => (
                    <Grid container alignItems="center" key={index} sx={{ mt: 2 }}>
                      <Grid item xs>
                        <TextField
                          label={`Anexo ${index + 1}`}
                          fullWidth
                          value={anexo}
                          onChange={(e) => handleAnexoChange(index, e.target.value)}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => handleRemoveAnexo(index)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>


                  ))}
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    variant="contained"
                    onClick={handleAddAnexo}
                  >
                    Agregar Anexo
                  </Button>
                </Grid>
              </>
            )}

          </Grid>

        </form>


      </Paper>
    </>
  );
}