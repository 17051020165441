import axios from 'axios';

// Función para obtener todas las entradas
export const fetchEntradas = async (token, page = 0, size = 10, sort = '', key = '') => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/entrada`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        sort,
        key
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al listar salas:', error);
    throw error;
  }
};


export const getDocumento = async(token, documentoId) =>{
 
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/digitalizacion/${documentoId}`,{
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    
    return response.data;

  } catch (error) {
    console.log('Error al descargar el documento: ', error);
    
    
  }

}

export const UpdateEstatus = async(token, documentoId, status) => {
  try {
     const response = await axios.patch(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/entrada/${documentoId}/status/${status}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
     );

     return response.data;
  } catch (error) {
    console.log('Error al actualizar el estado del documento: ', error);
    
  }
}


export const UpdateEstatusAnexos = async(token, anexos, documentoId) => {
  try {
    const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/carpeta/recepcion`,
     {},
     {
       headers: {
         Authorization: `Bearer ${token}`,
         anexos: anexos,
         documentoId:documentoId
       }
     }
    );

    return response.data;
 } catch (error) {
   console.log('Error al actualizar el estado del documento: ', error);
   
 }
}