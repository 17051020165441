import { Box } from "@mui/material"
import RefreshButton from "../../components/Button/RefreshButton"
import SearchInput from "../../components/Search/SearchInput"
import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../api/keycloakConf";
import { fetchInstituciones, remove } from "./institucionService";
import ConfirmationDialog from "../../components/Alert/ConfirmationDialog";
import ToastAlertDelete from "../../components/Alert/ToastAlertDelete/ToastAlertDelete";
import InstitucionesTable from "./Grid/InstitucionTable";
import { Link, useNavigate } from 'react-router-dom';
import ButtonAdd from "../../components/Button/ButtonAdd";

const Institucion = () => {

    const [loading, setLoading] = useState(true);
    const { getToken } = useContext(keycloakConf);
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchQuery, setSearchQuery] = useState('');
    const [instituciones, setData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success');
    const [itemToDelete, setItemToDelete] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        loadInstituciones();
    }, [getToken, page, rowsPerPage]);

    const loadInstituciones = async (query = "") => {
        setLoading(true);
        const token = getToken();
        try {
            const institucionData = await fetchInstituciones(token, page, rowsPerPage, '', query);
            setData(institucionData.content);
            setPageable(institucionData.page)
        } catch (error) {
            console.error('Error cargando registros:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleConfirmDelete = async () => {
        const token = getToken();
        try {
            await remove(token, itemToDelete);
            setData(instituciones.filter(institucion => institucion.id !== itemToDelete));
            setToastMessage('Registro eliminado con éxito');
            setToastType('success');
        } catch (error) {
            console.error('Error eliminando registro:', error);
            setToastMessage('El registro no fue eliminado');
            setToastType('error');
        }
        setToastOpen(true);
        setDialogOpen(false);
    };

    const handleCloseToast = () => {
        setToastOpen(false);
    };

    const handleEdit = (item) => {
        navigate('/api/core/instituciones/editar_institucion', { state: { item } });
    };

    const handleDelete = (id) => {
        setDialogMessage(`El registro seleccionado será permanentemente eliminado del sistema.`);
        setItemToDelete(id);
        setDialogOpen(true);
    };

    const handleRefresh = () => {
        setSearchQuery("");
        loadInstituciones();
    };

    return (<>
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
                <Link to="/api/core/instituciones/crear_institucion">
                    <ButtonAdd ariaLabel="añadir nueva institución" />
                </Link>
                &nbsp;
                <RefreshButton onClick={handleRefresh} />


            </Box>
            <Box flexBasis="300px" ml={2}>
                <SearchInput
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Buscar "
                    ariaLabel="Campo de busqueda para instituciones"
                    tooltipTitle="Escriba para buscar"
                    onKeyDown={(e) => e.key === 'Enter' && loadInstituciones(searchQuery)}
                />
            </Box>
        </Box>

        <Box>
            {loading ? <p>Cargando ... </p> : (

                <InstitucionesTable
                    instituciones={instituciones}
                    token={getToken()}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />

            )}
        </Box>

        <ConfirmationDialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmDelete}
            message={dialogMessage}
        />

        <ToastAlertDelete
            open={toastOpen}
            onClose={handleCloseToast}
            message={toastMessage}
            type={toastType}
        />
    </>)
}

export default Institucion;