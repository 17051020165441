import { Box } from "@mui/material";
import RefreshButton from "../../components/Button/RefreshButton";

import SearchInput from "../../components/Search/SearchInput";
import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../api/keycloakConf";
import BandejaTable from "./Grid/BandejaTable";
import ConfirmationDialog from "../../components/Alert/ConfirmationDialog";
import ToastAlertDelete from "../../components/Alert/ToastAlertDelete/ToastAlertDelete";
import { fetchEntradas } from "./BandejaService";

const Bandeja = () => {
    const [bandejas, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { getToken } = useContext(keycloakConf);
    const [loading, setLoading] = useState(true);
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success');
    const [isDigitalizacion, setIsDigitalizacion] = useState(false);

    useEffect(() => {
        loadBandejas();
    }, [getToken, page, rowsPerPage]);

    const loadBandejas = async () => {
        setLoading(true);
        const token = getToken();
        try {
            const bandejaData = await fetchEntradas(token, page, rowsPerPage, '', searchQuery);
            setData(bandejaData.content);
            setPageable(bandejaData.page)
        } catch (error) {
            console.error('Error cargando registros:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleConfirmDelete = async () => {
        const token = getToken();
    };

    const handleCloseToast = () => {
        setToastOpen(false);
    };

    return (
        <>

            {!isDigitalizacion && ( // Condición para mostrar los elementos
                <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <RefreshButton onClick={loadBandejas} />
                    </Box>
                    <Box flexBasis="300px" ml={2}>
                        <SearchInput
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Buscar "
                            ariaLabel="Campo de busqueda para bandeja de entrada"
                            tooltipTitle="Escriba para buscar"
                            onKeyDown={(e) => e.key === 'Enter' && loadBandejas()}
                        />
                    </Box>
                </Box>
            )}

            <Box>
                {loading ? <p>Cargando ... </p> : (

                    <BandejaTable
                        bandejas={bandejas}
                        token={getToken()}
                        setIsDigitalizacion={setIsDigitalizacion}
                        loadBandejas={loadBandejas}
                        pageable={pageable}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />

                )}
            </Box>

            <ConfirmationDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
                message={dialogMessage}
            />

            <ToastAlertDelete
                open={toastOpen}
                onClose={handleCloseToast}
                message={toastMessage}
                type={toastType}
            />
        </>
    )
};

export default Bandeja;