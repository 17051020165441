import axios from 'axios';

const urlCarpeta = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documento/promocion`;

// Función para añadir promocion
export const addPromocion = async (token, data) => {
    try {
        const response = await axios.post(urlCarpeta, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
        });
        return response.data;
    } catch (error) {
        console.error('Error al guardar promoción: ', error);
        throw error;
    }
};

// Función para obtener todas las Salidas
export const fetchSalidas = async (token, page = 0, size = 10, sort = '', key = '') => {
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/salida`, { 
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          sort,
          key
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error al obtener Salidas:', error);
      throw error;
    }
  };