import React from "react";
import { Link, useLocation } from "react-router-dom";
import breadcrumbConfig from "./breadcrumbConfig";
import { Breadcrumbs as MUIBreadcrumbs, Typography, Box } from "@mui/material";
import useMenuItems from "../../hooks/Menu/useMenuItems";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const { items } = useMenuItems();

  const breadcrumbs = pathnames.map((_, index, arr) => {
    const path = `/${arr.slice(0, index + 1).join("/")}`;
    return {
      path,
      label: breadcrumbConfig[path] || path,
    };
  });

  if (location.pathname !== "/home") {
    breadcrumbs.unshift({
      path: "/home",
      label: breadcrumbConfig["/home"] || "Inicio",
    });
  }

  const filteredBreadcrumbs = breadcrumbs.filter(
    (breadcrumb) => breadcrumbConfig[breadcrumb.path]
  );

  let currentModule = null;
  items.forEach((item) => {
    const foundItem = item.items.find(
      (subItem) => subItem.path === location.pathname
    );

    if (foundItem) {
      currentModule = item;
      filteredBreadcrumbs.splice(1, 0, {
        path: item.path,
        label: item.displayName,
      });
    } else {
      const basePath = item.items.find((subItem) =>
        location.pathname.includes(subItem.path)
      );
      if (basePath) {
        currentModule = item;
        filteredBreadcrumbs.splice(1, 0, {
          path: item.path,
          label: item.displayName,
        });
      }
    }
  });

  return (
    <Box sx={{ mb: 1.5, borderBottom: "1px solid #e0e0e0", pb: 1 }}>
      <MUIBreadcrumbs aria-label="breadcrumb" separator="/">
        {filteredBreadcrumbs.map((breadcrumb, index) => {
          const isMenuItem = items.some(
            (item) => item.path === breadcrumb.path
          );

          return index === filteredBreadcrumbs.length - 1 ? (
            <Typography key={breadcrumb.path}>{breadcrumb.label}</Typography>
          ) : isMenuItem ? (
            <Typography key={breadcrumb.path}>{breadcrumb.label}</Typography>
          ) : (
            <Link
              key={breadcrumb.path}
              style={{ textDecoration: "none", color: "#636569" }}
              to={breadcrumb.path}
            >
              {breadcrumb.label}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;
