import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem, Button } from '@mui/material';
import { styled } from '@mui/system';
import AccountCircle from '@mui/icons-material/AccountCircle';
import keycloakConf from '../../api/keycloakConf';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#f5f5f5', 
  color: 'black',
  zIndex: 1201, 
  marginLeft: 240, 
  width: `calc(100% - 240px)` 
}));

const Header = () => {
  const { getToken, logout } = useContext(keycloakConf); 
  
  const [anchorEl, setAnchorEl] = useState(null);
  
  const token = getToken();
  const userPayload = token ? JSON.parse(atob(token.split('.')[1])) : null; 
  const firstName = userPayload ? userPayload.given_name : 'Nombre no disponible'; 
  const lastName = userPayload ? userPayload.family_name : 'Apellido no disponible';
  const userEmail = userPayload ? userPayload.email : 'Correo no disponible'; 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledAppBar position="fixed">
      <Toolbar sx={{ color: '#fff', background: '#636569'}}>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="h6">{firstName} {lastName}</Typography> 
        <IconButton edge="end" color="inherit" onClick={handleClick}>
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem disabled>{userEmail}</MenuItem>
          <MenuItem>
            <Button variant="outlined" sx={{ color: '#8c92bc', borderColor: '#8c92bc'}} onClick={() => { 
              logout(); 
              handleClose(); 
            }}>
              Cerrar sesión
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
