import axios from 'axios';

// Función para obtener todos los juzgados
export const fetchEncargadosCarrito = async (token) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/personas/encargadocarrito`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener los Encargados del Carrito:', error);
        throw error;
    }
};
